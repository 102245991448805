import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import envioPostal from '../../../assets/icons/envio-postal.png';
import portador from '../../../assets/icons/portador.png';
import retirada from '../../../assets/icons/retirada.png';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InformacoesPedido from '../../components/informacoesPedido';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Modal } from '@mui/material';
import { getClienteCodigo } from '../../../utils/localStorage';
import { getClienteByCodigo } from '../../../services/modules/cliente/clienteService';
import { Cliente } from '../../../models/modules/Cliente/Cliente';
import { PageLoading } from '../../../components/Loading/Loading';
import { calcularDiaUtil, isEmptyObject } from '../../../utils/utils';
import {
  Endereco,
  EnderecoItem,
} from '../../../models/modules/Cliente/Endereco';
import { AlertNotify } from '../../../components/alerts/AlertNotify';
import { postOperacao } from '../../../services/modules/cliente/operacaoService';
import { getCalculoCEP } from '../../../services/modules/viacep/viacep';
import { CalculoFrete } from '../../../models/modules/Cliente/Cep';
import { db } from '../../../services/dexie/stores';
import { useNavigate } from 'react-router-dom';
import { format, getDay } from 'date-fns';

export default function FormasEntrega({desconto}) {
  const lojas = ['Matriz Ourominas - SP'];

  const [lojaSelecionada, setLojaSelecionada] = useState('');
  const [opcaoEntrega, setOpcaoEntrega] = useState();
  const [dropdownVisivel, setDropdownVisivel] = useState(false);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [data, setData] = useState<Cliente>();
  const [endereco, setEndereco] = useState<Endereco>(null);
  const [formaEntrega, setFormaEntrega] = useState<CalculoFrete>(null);
  const [selectedValue, setSelectedValue] = useState('envioPostal');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const getCliente = async () => {
      deleteItem('endereco');
      const resp = await db.getCarrinho();
      //Busca os itens no carrinho e remove o item endereco
      const coins = resp.filter(item => item.tipo !== 'brinde' && item.id !== 'endereco');
      if (coins.length !== 1) {
        navigate('/');
      }
      const codigo = getClienteCodigo();
      setLoad(true);
      getClienteByCodigo(codigo)
        .then(setData)
        .catch((e) => e)
        .finally(() => {
          setLoad(false);
        });
    };
    getCliente();
  }, []);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleCloseEmpty = () => {
    setOpenModal(false);
    setSelectedDate(null);

    addImageOpcao('envioPostal');
  };

  const addImageOpcao = (value) => {
    document
      .getElementById('envioPostal')
      .classList.remove('imageOpcao__selecionado');
    document
      .getElementById('portador')
      .classList.remove('imageOpcao__selecionado');
    document
      .getElementById('retirada')
      .classList.remove('imageOpcao__selecionado');

    setOpcaoEntrega(value);
    document.getElementById(value).classList.add('imageOpcao__selecionado');

    setSelectedValue(value);
  };

  const handleSelecionarLoja = (loja) => {
    setLojaSelecionada(loja);
    setDropdownVisivel(false);
    if (opcaoEntrega === 'retirada' || opcaoEntrega === 'portador') {
      setCalendarVisible(true);
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value === 'retirada' || value === 'portador') {
      setCalendarVisible(true);
      setOpenModal(true);
    } else setCalendarVisible(false);

    addImageOpcao(value);
  };

  useEffect(() => {
    if (data) {
      if (!isEmptyObject(data.enderecoAlternativo)) {
        setEndereco(data.enderecoAlternativo);
      } else {
        setError('Para continuar, atualize seu endereço de entrega.');
      }
    }
  }, [data]);

  const onFinally = async () => {
    if (!endereco) {
      AlertNotify({
        message: 'Para continuar, atualize seu endereço de entrega!',
        type: 'warning',
      });
      return null;
    }
    let observacao = '';
    let tipoEntrega = '';
    let valorFrete = null;
    switch (selectedValue) {
      case 'envioPostal':
        tipoEntrega = 'SEDEX';
        observacao = 'Forma de Entrega: DELIVERY END ALTERNATIVO';
        break;

      case 'portador':
        tipoEntrega = 'DELIVERYT';
        observacao = `Entrega Portador com data agendada agendada para o dia: ${format(
          selectedDate,
          'dd/MM/yyyy'
        )}`;
        break;
      case 'retirada':
        if (!lojaSelecionada) {
          AlertNotify({
            message: 'Selecione uma loja mais próxima',
            type: 'warning',
          });
          return;
        }
        tipoEntrega = 'BALCAO';
        observacao = `Retirada na Matriz com data agendada para o dia: ${format(
          selectedDate,
          'dd/MM/yyyy'
        )}`;
        break;
      default:
        return;
    }
    setLoad(true);
    if (selectedValue !== 'retirada') {
      if (!formaEntrega) return null;
      valorFrete = parseFloat(formaEntrega.valorFrete.replace(',', '.'));
    }
    try {
      await postOperacao(valorFrete, tipoEntrega, observacao)
        .then((resp) => {
          navigate(
            `/om/servicos-cambio/moedas-estrangeiras/finalizar-pedido/pagamento/${resp}`
          );
          window.scrollTo(0, 0);
          AlertNotify({ message: 'Operação criada com sucesso!' });
          db.cleanCarrinho();
        })
        .catch((e) => e)
        .finally(() => setLoad(false));
    } catch (error) {
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (endereco) {
      setLoad(true);
      getCalculoCEP(endereco.cep)
        .then((resp) => {
          setFormaEntrega(resp);
          setFreteCarrinho(resp);
        })
        .catch(() =>
          AlertNotify({
            message:
              'Ocorreu um erro ao calcular o frete, recarregue a página ou tente novamente mais tarde.',
          })
        )
        .finally(() => setLoad(false));
    }
  }, [endereco]);

  async function setFreteCarrinho(data: CalculoFrete) {
    const tempo = parseInt(data.prazoFrete) + 1;
    let frete = {
      tipo: 'endereco',
      valor: parseFloat(data.valorFrete.replace(',', '.')),
      nome: `Frete (prazo de entrega: ${tempo} ${tempo > 1 ? 'dias' : 'dia'}) `,
      quantidade: 1,
      id: 'endereco',
    };
    await db.setItem(frete, 'endereco');
  }

  async function deleteItem(id) {
    await db.deleteItemCarrinho(id);
  }

  useEffect(() => {
    if (selectedDate)
      AlertNotify({
        message: `Sua entrega foi agendada para: ${format(
          selectedDate,
          'dd/MM/yyyy'
        )}`,
      });
  }, [selectedDate]);

  useEffect(() => {
    if (selectedValue) {
      updateEndereco();
    }
    function updateEndereco() {
      if (!data?.enderecoAlternativo) return;
      if (selectedValue === 'retirada') {
        deleteItem('endereco');
      } else {
        setFreteCarrinho(formaEntrega);
      }
    }
  }, [selectedValue]);
  return (
    <MenuPrincipal>
      <div className="container">
        {load && <PageLoading />}
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            <TituloPrincipal>
              <h1>Formas de entrega</h1>
              <p>
                {endereco
                  ? 'Selecione a forma de entrega de sua compra abaixo'
                  : 'Cadastre um endereço para continuar'}
              </p>
            </TituloPrincipal>
          </FormLabel>
          <>
            {endereco && (
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={selectedValue}
                name="radio-buttons-group"
                onChange={handleChange}
              >
                <FormularioSelecao>
                  <div className="imageOpcao">
                    <FormControlLabel
                      value="envioPostal"
                      control={<Radio />}
                      label=" "
                    />
                    <div id="envioPostal" className="imageOpcao__selecionado">
                      <img src={envioPostal} alt="" width="70px" />
                      <p>Envio Postal</p>
                    </div>
                  </div>
                  <div className="detalhesEntrega">
                    <p>
                      Limite de compra: Até <span>R$ 10.000,00</span>
                    </p>
                    <p>
                      Custo de entrega: <span>Frete + Seguro (1,5%)</span>
                    </p>
                  </div>
                </FormularioSelecao>
                <FormularioSelecao>
                  <div className="imageOpcao">
                    <FormControlLabel
                      value="portador"
                      control={<Radio />}
                      label=" "
                    />
                    <div id="portador">
                      <img src={portador} alt="" width="70px" />
                      <p>Portador</p>
                    </div>
                  </div>
                  <div className="detalhesEntrega">
                    <p>
                      Limite de compra: Até <span>R$ 10.000,00</span>
                    </p>
                    <p>
                      Custo de entrega: <span>Frete + Seguro (1,5%)</span>
                    </p>
                    <p>
                      Entrega gratuita: A partir de <span>R$ 200,00</span>
                    </p>
                    {selectedDate && selectedValue === 'portador' && (
                      <p>
                        <span>
                          Entrega agendada para:{' '}
                          <span style={{ color: 'red' }}>
                            {format(selectedDate, 'dd/MM/yyyy')}
                          </span>
                        </span>
                      </p>
                    )}
                  </div>
                  {opcaoEntrega === 'portador' && calendarVisible && (
                    <Modal
                      open={openModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <AgendamentoPortador>
                        <h3>
                          Agende sua Retirada
                          <button onClick={handleCloseEmpty}>X</button>
                        </h3>
                        <CustomDatePicker
                          setSelectedDate={setSelectedDate}
                          showDatePicker={calendarVisible}
                          //toggleDatePicker={toggleDatePicker}
                          onClose={handleClose}
                          minDate={calcularDiaUtil(
                            parseInt(formaEntrega.prazoFrete)
                          )}
                        />
                        <div className="avisoEntrega">
                          <p>
                            Caso o cliente ou a pessoa autorizada não esteja
                            presente no local da entrega, a mercadoria retornará
                            a Ourominas e o cliente deve entrar em contato com a
                            empresa para combinar uma nova data de entrega. O
                            reenvio será cobrado.
                          </p>
                        </div>
                      </AgendamentoPortador>
                    </Modal>
                  )}
                </FormularioSelecao>
                <FormularioSelecao>
                  <div className="imageOpcao">
                    <FormControlLabel
                      value="retirada"
                      control={<Radio />}
                      label=" "
                    />
                    <div id="retirada">
                      <img src={retirada} alt="" width="70px" />
                      <p>Retirada</p>
                    </div>
                  </div>
                  <div className="detalhesEntrega">
                    <p>Escolha a loja mais próxima</p>
                    <SelecaoLojaContainer>
                      <SelectLoja
                        onClick={() => setDropdownVisivel(!dropdownVisivel)}
                      >
                        <span className="lojas">
                          {lojaSelecionada || 'Selecione'}
                        </span>
                        <KeyboardArrowDownIcon sx={styleSeta} />
                      </SelectLoja>
                      {dropdownVisivel && (
                        <DropdownContent id="lojas-dropdown">
                          {lojas.map((loja) => (
                            <li
                              className="lojas"
                              key={loja}
                              onClick={() => handleSelecionarLoja(loja)}
                            >
                              {loja}
                            </li>
                          ))}
                        </DropdownContent>
                      )}
                      {opcaoEntrega === 'retirada' && calendarVisible && (
                        <Modal
                          open={openModal}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Agendamento>
                            <h3>
                              Agende sua Retirada
                              <button onClick={handleCloseEmpty}>X</button>
                            </h3>
                            <CustomDatePicker
                              setSelectedDate={setSelectedDate}
                              showDatePicker={calendarVisible}
                              onClose={handleClose}
                              minDate={calcularDiaUtil(
                                parseInt(formaEntrega.prazoFrete)
                              )}
                            />
                          </Agendamento>
                        </Modal>
                      )}
                    </SelecaoLojaContainer>
                    {selectedDate && selectedValue === 'retirada' && (
                      <p>
                        <span>
                          Retirada agendada para:{' '}
                          <span style={{ color: 'red' }}>
                            {format(selectedDate, 'dd/MM/yyyy')}
                          </span>
                        </span>
                      </p>
                    )}
                  </div>
                </FormularioSelecao>
              </RadioGroup>
            )}
          </>
        </FormControl>
        <div className="desktop">
          <BoxEndereco>
            {endereco && (
              <>
                <h5>Endereço de entrega</h5>
                <p>
                  {' '}
                  {endereco.logradouro}, {endereco.numeroLogradouro}{' '}
                  {endereco.cep} - {endereco.cidade} {endereco.uf}{' '}
                </p>
              </>
            )}
            {error && <p>{error}</p>}
          </BoxEndereco>

          <Button
            style={{ textTransform: 'inherit' }}
            onClick={() => navigate('/om/painel/perfil/endereco')}
          >
            Atualizar endereço
          </Button>
          {endereco && formaEntrega && (
            <InformacoesPedido
              linkButton={'#'}
              show={true}
              textButton={'Finalizar Pagamento'}
              onClick={onFinally}
              desconto={desconto}
            />
          )}
        </div>
      </div>
    </MenuPrincipal>
  );
}

const MenuPrincipal = styled.div`
  background-color: #f7f7f7;
  padding-bottom: 20px;
  font-family: CenturyGothic;
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .imageOpcao {
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
    justify-content: center;
    max-width: 180px;
    > label {
      margin: 0;
    }
    > div {
      text-align: center;
      width: 100%;
    }
  }

  .cep {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    width: 76%;
    margin: 15px auto;

    div {
      width: 100%;
    }
  }

  .consultarCep {
    background-color: var(--green);
    border-radius: 5px;
    border: none;
    color: var(--primary-white);
    font-size: 11px;
    text-transform: uppercase;
    width: 209px;
    height: 46px;
    padding: 3px 14px;
    margin-left: 5px;
    margin-top: 22px;
  }

  .cepDesktop {
    display: none;
  }

  .imageOpcao__selecionado {
    border: solid 3px #00896c;
    border-radius: 10px;
    height: 100px;
  }

  .desktop {
    width: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
    }
  }

  @media (min-width: 1000px) {
    height: 900px;

    .container {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    .desktop {
      flex-direction: column;
      align-items: center;
      width: 45%;
    }

    .cep {
      display: none;
    }

    .cepDesktop {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      width: 95%;
      margin: 50px 20px 10px;

      div {
        width: 100%;
      }
    }
  }

  @media (min-width: 1200px) {
    padding: 0 10vw;
  }

  @media (min-width: 1300px) {
    .desktop {
      width: 39%;
    }

    .cepDesktop {
      width: 100%;
      div {
        input {
          min-width: 260px;
        }
      }
    }

    .consultarCep {
      width: 180px;
    }
  }
`;

const TituloPrincipal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    margin: 30px 0 10px;
    color: var(--green);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 5px;
    font-family: CenturyGothicBold;
  }

  p {
    color: #87868a;
    font-weight: 500;
    font-size: 16px;
  }

  @media (min-width: 1000px) {
    p {
      margin-bottom: 30px;
    }
  }
`;

const FormularioSelecao = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  gap: 10px;
  @media (min-width: 720px) {
    flex-direction: row;
    justify-content: space-evenly;
    width: 550px;
  }
  p,
  .lojas {
    color: #87868a;
    font-weight: 500;
  }

  .lojas {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .detalhesEntrega {
    font-size: 15px;
    margin: 15px auto;
    width: 100%;
    text-align: center;

    > p {
      color: #5b5b5f;
      font-weight: 600;

      span {
        color: #004133;
      }
    }
  }

  @media (min-width: 1000px) {
    width: 490px;
  }
`;

const SelecaoLojaContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;

  .show {
    display: block;
    width: 100%;
    list-style: none;
  }

  .dropdown-content li {
    padding: 5px;
    cursor: pointer;
  }

  .dropdown-content li:hover {
    background-color: #ddd;
  }
`;

const SelectLoja = styled.div`
  appearance: none;
  padding: 8px;
  border: 1px solid var(--green);
  color: #87868a;
  cursor: pointer;
`;

const DropdownContent = styled.ul`
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const CustomCalendar = styled.div`
  margin-top: 10px;
`;

const Agendamento = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #f0f0f0c9;
  width: 100%;
  height: 100%;

  h3 {
    color: var(--green);
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-top: 150px;
  }

  button {
    margin-left: 50px;
  }
`;

const AgendamentoPortador = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #f0f0f0c9;
  width: 100%;
  height: 100%;

  h3 {
    margin-top: 150px;
    color: var(--green);
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 5px;
    text-align: center;
  }

  button {
    margin-left: 50px;
  }

  .avisoEntrega {
    width: 270px;
    height: 100px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid var(--light-gray);
    padding: 15px;
    text-align: center;
  }

  .avisoEntrega > p {
    font-size: 10px;
    color: var(--gray);
  }
`;

const BoxEndereco = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  font-family: CenturyGothicBold;
  color: var(--green);

  > p {
    margin: 0;
  }
`;

function CustomDatePicker({
  setSelectedDate,
  showDatePicker,
  onClose,
  minDate,
}) {
  const handleDateChange = (date) => {
    setSelectedDate(date);

    if (date) {
      onClose();
    }
  };
  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  return (
    <CustomCalendar
      className={`custom-datepicker ${showDatePicker ? 'show' : ''}`}
    >
      {showDatePicker && (
        <DatePicker
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
          inline
          minDate={minDate}
          filterDate={isWeekday}
        />
      )}
    </CustomCalendar>
  );
}

const styleSeta = {
  background: 'var(--green)',
  color: '#fff',
  width: '60px',
  height: '40px',
  position: 'absolute',
  top: '0',
  right: '0',
  cursor: 'pointer',
};

const ButtonProsseguir = styled.button`
  color: var(--primary-white);
  background-color: var(--green);
  border: none;
  border-radius: 5px;
  width: 270px;
  height: 45px;
  margin-top: 30px;

  width: 400px;
`;
