import React, { useEffect, useState } from 'react';
import './brindes.css';
import abridor from '../../../../assets/images/brindes/abridor.png';
import caderneta from '../../../../assets/images/brindes/caderneta.png';
import caneta from '../../../../assets/images/brindes/caneta.png';
import doleira from '../../../../assets/images/brindes/doleira.png';
import lapis from '../../../../assets/images/brindes/lapis.png';
import setaEsq from '../../../../assets/icons/arrow-left-green.png';
import setaDir from '../../../../assets/icons/arrow-right-green.png';

export interface Brinde {
  item: string;
  valorMinimo: number;
  descricao?: string;
  image?: string;
}

interface BrindesComponentProps {
  valorCompra: number;
  onAddBrinde: (brinde: Brinde) => void;
  onRemoveItem: (item: string) => void;
  carrinho: string[];
  onToggleBrinde: (brinde: Brinde) => void;
}

const brindesDisponiveis: Brinde[] = [
  {
    item: 'Doleira',
    valorMinimo: 2000,
    descricao: 'Disponível para compras acima de 2000',
    image: doleira,
  },
  {
    item: 'Chaveiro/abridor',
    valorMinimo: 500,
    descricao: '2G de ouro',
    image: abridor,
  },
  {
    item: 'Caderneta c/caneta',
    valorMinimo: 2000,
    descricao: '10G de ouro',
    image: caderneta,
  },
  { item: 'Caneta', valorMinimo: 100, descricao: '1G de ouro', image: caneta },
  { item: 'Lápis', valorMinimo: 100, descricao: '1G de ouro', image: lapis },
  { item: 'Boné', valorMinimo: 5000, descricao: '25G de ouro', image: lapis },
];

const BrindesComponent: React.FC<BrindesComponentProps> = ({ valorCompra, carrinho = [], onToggleBrinde }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [brindeSelecionado, setBrindeSelecionado] = useState<Brinde | null>(null);

  const itemsPerPage =
    window.innerWidth >= 900 ? 4 : window.innerWidth >= 680 ? 3 : 2;

  const scrollLeft = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - itemsPerPage, 0));
  };

  const scrollRight = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + itemsPerPage, brindesDisponiveis.length - itemsPerPage)
    );
  };

  const handleBrindeClick = (brinde: Brinde) => {
    if (valorCompra >= brinde.valorMinimo && !carrinho.includes(brinde.item)) {
      onToggleBrinde(brinde);
      setBrindeSelecionado(brinde);
    }
  };

  useEffect(() => {
    const brindeNoCarrinho = carrinho.find((item) => item === brindeSelecionado?.item);
    if (brindeNoCarrinho) {
      setBrindeSelecionado(null);
    }
  }, [carrinho, brindeSelecionado]);

  return (
    <div className="brindes">
      <h3>Escolha seu brinde Ourominas</h3>
      <div className="brindes-container">
        <img
          src={setaEsq}
          alt="Seta Esquerda"
          className="arrow"
          onClick={scrollLeft}
        />
        <div className="brindes-grid">
          {brindesDisponiveis
            .slice(currentIndex, currentIndex + itemsPerPage)
            .map((brinde, index) => {
              const isAvailable = valorCompra >= brinde.valorMinimo && !carrinho.includes(brinde.item);
              const isSelected = brindeSelecionado && brindeSelecionado.item === brinde.item;

              return (
                <div
                  key={index}
                  className={`brinde-item ${!isAvailable ? 'unavailable' : ''} ${isSelected ? 'selected' : ''}`}
                  onClick={() => isAvailable && handleBrindeClick(brinde)}
                >
                  <div className="brinde-overlay">
                    <p>
                      {brinde.item} {!isAvailable && ''}
                    </p>
                  </div>
                  <img
                    src={brinde.image}
                    alt={brinde.item}
                    className="brinde-image"
                  />
                </div>
              );
            })}
        </div>
        <img
          src={setaDir}
          alt="Seta Direita"
          className="arrow"
          onClick={scrollRight}
        />
      </div>
      <hr />
      <h3 className="interesse">
        Os produtos abaixo podem lhe interessar: (em breve)
      </h3>
    </div>
  );
};

export default BrindesComponent;
