import React, { useEffect, useState } from 'react';
import './carrinhoCompras.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReturnName, ReturnNamePage } from '../../../utils/coins';
import CountryFlags from '../../../utils/countryFlags';
import DetalhesPedido from '../detalhes-pedido/detalhesPedido';
import InformacoesPedido from '../../components/informacoesPedido';
import EmbalagemProtetora from '../detalhes-pedido/embalagemProtetora';
import styled from 'styled-components';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../../services/dexie/stores';
import { PageLoading } from '../../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddIcon from '@mui/icons-material/Add';
import BrindesComponent from './brindes/brindes';

const styleIcons = {
  width: '.8rem',
  justifyContent: 'center',
  marginRight: '5px',
};

const styleNext = {
  color: '#ffffff',
};

export default function CarrinhoCompras() {
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState([]);
  const [valid, setValid] = useState(true);
  const [load, setLoad] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [messageStyle, setMessageStyle] = useState('');
  const [totalCompra, setTotalCompra] = useState(0);
  const [cupom, setCupom] = useState('');
  const [desconto, setDesconto] = useState(0);

  const observable = useLiveQuery(() => db.carrinho.toArray());
  const navigate = useNavigate();

  useEffect(() => {
    async function set() {
      setMessageError('');
      const resp = await db.getCarrinho();
      let value = 0;
      let message = '';
      setValid(true);

      const coins = resp.filter(item => item.tipo !== 'brinde' && item.id !== 'endereco');

      if (coins.length !== 1) {
        message +=
          'Neste momento, para finalizar a compra, só é permitido comprar uma moeda por vez. Utilize apenas uma para continuar.';
        setValid(false);
      }
      coins.map((item) => {
        const valor = item.valor * item.quantidade;

        //Verifica o limite aplicado para cada moeda
        if (valor > item.liberadoBR) {
          message += `\n\nO seu limite máximo para a moeda ${
            item.nome
          } é de ${parseFloat(item.liberadoBR).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}. Para finalizar a sua compra, escolha uma quantidade dentro desse valor.`;

          setValid(false);
        }
        return (value += item.valor * item.quantidade);
      });
      setMessageError(message);

      setTotalItems(coins.length);
      setData(coins);
      setTotalCompra(value);
    }
    setLoad(true);
    set().finally(() => setLoad(false));
  }, [observable]);

  const handleCupomChange = (event) => {
    setCupom(event.target.value);
  };

  const aplicarDesconto = async () => {
    try {
      const cupomValido = await db.validarCupom(cupom);

      if (!cupomValido) {
        setMessageError('Cupom inválido ou expirado.');
        setMessageStyle('error-message');
        setCupom('');
        return;
      }

      const valorMinimoCupom = cupomValido.valorMinimo;

      if (totalCompra < valorMinimoCupom) {
        setMessageError(
          `O valor mínimo de compra para aplicar o cupom é de R$ ${valorMinimoCupom}.`
        );
        setMessageStyle('error-message');
        return;
      }

      const moedasPermitidas = cupomValido.moedasPermitidas || [];

      const moedaPermitida = data.some((item) => {
        const nomeMoeda = (ReturnName(item?.codIso) || item?.nome)
          .toUpperCase()
          .trim();

        return moedasPermitidas.some(
          (moeda) => moeda.trim().toUpperCase() === nomeMoeda
        );
      });

      if (!moedaPermitida) {
        setMessageError(`O cupom não é aplicável para a moeda selecionada.`);
        setMessageStyle('error-message');
        return;
      }

      let valorDesconto = cupomValido.desconto;

      const novoTotalCompra =
        totalCompra - Math.min(valorDesconto, totalCompra);

      if (novoTotalCompra < 0) {
        setMessageError('O desconto não pode ser maior que o valor da compra.');
        setMessageStyle('error-message');
        return;
      }

      setDesconto(valorDesconto);
      setTotalCompra(novoTotalCompra);
      setMessageError('Cupom aplicado com sucesso!');
      setMessageStyle('success-message');
    } catch (error) {
      setMessageError('Erro ao aplicar o cupom. Por favor, tente novamente.');
      setMessageStyle('error-message');
    }
  };

  const onToggleBrinde = (value) => {
    console.log(value)
    const brinde = {
      id: 'brinde',
      nome: value.item,
      quantidade: 1,
      valor: 0,
      tipo: 'brinde',
      ...value
    }
    db.setItem(brinde, 'brinde');

  }
  return (
    <>
      <div className="principal">
        <div className="carrinho">
          <div className="itens">
            <h3>Carrinho de compras</h3>
            <div>
              <p>{totalItems}</p>
              <p>{totalItems === 1 ? 'item' : 'items'}</p>
            </div>
          </div>
          <div>
            {load && <PageLoading />}
            <hr />
            {data?.map((item) => (
                <>
                  <div className="menuPrincipal">
                    <div className="menuCompras">
                      <div className="div1">
                        <CountryFlags cod={item.codIso} />
                        <div>
                          <p style={{ color: '#004133', fontWeight: '600' }}>
                            <span>
                              {ReturnName(item?.codIso)?.split(' ', 1) ||
                                item?.nome}
                            </span>{' '}
                            <span>
                              ({item.codIso === 'EUSCOM' ? 'USD' : item.codIso})
                            </span>
                          </p>
                          <p>{ReturnNamePage(item.tipo)}</p>
                        </div>
                      </div>
                      <div className="div2">
                        <p>Quantidade:</p>
                        <p className="valor">{item.quantidade}</p>
                        <p>Total:</p>
                        <p className="valor">
                          {parseFloat(
                            item.quantidade * item.valor
                          ).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </p>
                        <div>
                          <button>
                            <DetalhesPedido item={item} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detalhes">
                    <button className="remover" onClick={() => {
                              db.deleteItemCarrinho(item.id);
                            }}>
                      <DeleteIcon style={styleIcons} />
                      Remover
                    </button>
                    <button className="remover" onClick={() => {
                              navigate("/om/servicos-cambio/moedas-estrangeiras")
                            }}>
                      <AddIcon style={styleIcons} />
                      Adicionar mais produtos
                    </button>
                    <EmbalagemProtetora />
                    <p className="remover">Tem cupom de desconto? (em breve)</p>
                    <div className="codigoPromocional">
                      <input
                        type="text"
                        placeholder="Código"
                        value={cupom}
                        onChange={handleCupomChange}
                      ></input>
                      <button className="next" onClick={aplicarDesconto}>
                        <NavigateNextIcon style={styleNext} />
                      </button>
                    </div>
                    {messageError && (
                      <div
                        className={`message ${messageStyle}`}
                        style={{
                          color:
                            messageStyle === 'error-message' ? 'red' : 'green',
                          fontWeight: 'bold',
                          marginTop: '10px',
                          fontSize: '0.9rem',
                        }}
                      >
                        {messageError}
                      </div>
                    )}
                  </div>
                </>
              ))}
          </div>
        <BrindesComponent onToggleBrinde={onToggleBrinde} valorCompra={totalCompra}  carrinho={data}/>
        </div>


        <div className="informacoesPedido">
          <p>{messageError}</p>
          <InformacoesPedido
            linkButton="formas-entrega"
            textButton="Formas de entrega"
            show={valid}
            desconto={desconto}

          />
        </div>
      </div>
    </>
  );
}